import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import { DynamicBarChart } from 'react-dynamic-charts';
import 'react-dynamic-charts/dist/index.css'; // Don't forget to import the styles
//var csv = require('./GoalsByCountry.csv');

var Papa = require("papaparse/papaparse.min.js");



class App extends Component {

  constructor (props){
    super(props);
    this.state = {data:[]};

    this.updateState = this.updateState.bind(this);
    this.getResults = this.getResults.bind(this);
  }

  updateState(data){
       this.setState(function(state, props) {
        return {
          data: data 
        };
      }); 
  }


  getResults(results){
          var data = [];
          //console.log(results.data)

          var rowLength = Object.keys(results.data[0]).length;

          for(var col=4;col<rowLength;col=col+2){

            var roundData = {
              "name": results.data[0][col]
            };

           //console.log(roundData);
           var rowData = [];
            for(var row=1;row<results.data.length;row++){
              
              var color = results.data[row][3];
              console.log(color);
              console.log(typeof color);

                     

             rowData[row] =  
                  {
                    "id": results.data[row][0],
                    "label": (<React.Fragment>
                      {results.data[row][1]} <img class="flag-img" src={'img/flags/'+results.data[row][2]+'.png'} alt={results.data[row][1]} /> 
                    </React.Fragment>),
                    "value": results.data[row][col+1],
                    "color": color
                  };
                  console.log(rowData[row]);
               
            }
            roundData['values'] = rowData;

            data.push(roundData);
          }

          console.log(data);

          console.log(this.state);
         /* state = {data: data};
          console.log(state);*/
          this.updateState(data);
        
  }

  

  componentDidMount() {
    console.log('didmount');

    var outputData = [];

    var csvFilePath = require("./GoalsByCountry.csv");
    var csvFile = Papa.parse(csvFilePath, {
        header: false,
        download: true,
        delimeter: ',',
        complete: this.getResults
    });

  }

   

  renderChart(){
    return (
      <div className="App">
        <header className="App-header">
         
         <DynamicBarChart
          data={this.state.data} 
          iterationTimeout={1000} 
          startRunningTimeout={2000} 
          barHeight={30}
        />
          
        </header>
      </div>
    );
  }


  render() {

    console.log('render');


    console.log(this.state);


    return this.state.data.length ? this.renderChart() : (
      <span>Loading wells...</span>
    )
  }
}

export default App;
